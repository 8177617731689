<template>
  <!--<portal to="sidebar">
    <h3 class="sidebar__nav-heading">Subnavigation</h3>
    <nav class="sidebar__nav">
      <a href="#">Link</a>
      <a href="#">Link</a>
      <a href="#">Link</a>
      <a href="#">Link</a>
    </nav>
  </portal>-->
  <promise invoke="getClient" :args="{ id: user.wfm_client_uuid }" @done="ready" @ready="updateTitle">
    <template #content="props">
      <header class="main-header">
        <div class="row no-gutters align-items-center">
          <div class="col-auto">
            <Avatar :string="props.data.clientName" class="userinfo__image-container--xl"/>
          </div>
          <div class="col-auto">
            <h1 class="main-header__title">{{ props.data.clientName }}</h1>
          </div>
        </div>
      </header>
      <section class="main-content">
        <div class="row">
          <div class="col-lg-6 mb-5 mb-lg-0">
            <!-- documents -->
            <h3 class="mt-0 mb-3">Documents</h3>
            <table class="table table--sm table--align-left">
              <thead>
                <tr>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="document in props.data.clientDocuments" :key="document.id">
                  <td>
                    <a :href="document.url" target="_blank" rel="noopener" class="link__link">
                      <span class="link__title">{{ document.title }}</span>
                      <span class="link__url">{{ document.document_type === 'file' ? document.original_filename : document.url }}</span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-6">
            <!-- contacts -->
            <h3 class="mt-0">Contacts</h3>
            <div class="row">
              <div class="col-6">
                <user-card :contact="{ name: 'John Smith', position: 'Account Manager', email: 'hello@welovechatter.com', mobile: '01135240390' }"/>
              </div>
              <div class="col-6">
                <user-card :contact="{ name: 'Jane Doe', position: 'Job Manager', email: 'hello@welovechatter.com', mobile: '01135240390' }"/>
              </div>
            </div>
          </div>
        </div>
        <!-- primary contacts -->
        <template v-if="hasAtleastOnePrimaryContact(props.data.contacts)">
          <h3 class="mt-5">Key Contacts</h3>
          <div class="row mb-5">
            <div v-for="contact in props.data.contacts" :key="contact.contactUUID" class="col-3">
              <user-card v-if="contact.isPrimary" :contact="contact"/>
            </div>
          </div>
        </template>
        <!-- quotation -->
        <modal v-for="quote in props.data.quotes" :key="quote.id" :id="`view-quote-${slugifyQuote(quote)}`" class="modal--lg modal-view-quote" @close="quoteModalClosed">
          <template #title>Quote {{ quote.ID }} - {{ quote.Name }} <span class="status-bubble status-bubble--sm ml-2" style="vertical-align:middle">{{ quote.status }}</span></template>
          <template #content>
            <div class="row h-100">
              <div class="col-8">
                <object :data="`https://stg.dashboard.apps.welovechatter.com/quote/${props.data.clientUUID}/${slugifyQuote(quote)}/pdf#page=1&view=FitH`" type="application/pdf" class="pdf-preview">
                  <!--<iframe src="https://docs.google.com/viewer?url=your_url_to_pdf&embedded=true"></iframe>-->
                </object>
              </div>
              <div class="col-4 d-flex flex-column">
                <!-- quote actions -->
                <div v-if="quote.status === 'sent'" class="quote__actions">
                  <h3 class="mt-0 mb-0">Actions</h3>
                  <p class="heading-desc mt-1 mb-3">If you're happy with everything, hit Accept, if you wish to make any changes, click Amend and enter a comment.</p>
                  <div class="quote__actions__buttons mb-4 pb-4">
                    <router-link replace :to="`/client-dashboard/quote/${slugifyQuote(quote)}/accept`" class="button button--grey mr-1">Accept</router-link>
                    <router-link replace :to="`/client-dashboard/quote/${slugifyQuote(quote)}/amend`" class="button button--grey mr-1">Amend</router-link>
                    <router-link replace :to="`/client-dashboard/quote/${slugifyQuote(quote)}/reject`" class="button button--grey">Reject</router-link>
                  </div>

                  <!-- accept -->
                  <div v-if="quoteAction === 'accept'" class="quote__actions__accept">
                    <h4 class="mt-0 mb-0">Accept</h4>
                    <p class="heading-desc mt-1 mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra, odio vel porttitor tempor, metus augue.</p>
                    <button class="button" @click="acceptQuote(props, quote)">Accept</button>
                    <router-link replace :to="`/client-dashboard/quote/${slugifyQuote(quote)}`" class="button button--grey ml-1">Cancel</router-link>
                  </div>

                  <!-- amend -->
                  <div v-if="quoteAction === 'amend'" class="quote__actions__amend">
                    <h4 class="mt-0 mb-0">Amend</h4>
                    <p class="heading-desc mt-1 mb-3">Describe the reason for the amendments and tell us what you'd like us to change.</p>
                    <ajax-form :url="`/quote/${props.data.clientUUID}/${slugifyQuote(quote)}/action`">
                      <template #fields>
                        <input type="hidden" name="action" value="amend"/>
                        <div class="form-group">
                          <label :for="`amend-reason-${quote.ID}`">Reason <abbr class="required">*</abbr></label>
                          <textarea name="comments" :id="`amend-reason-${quote.ID}`" class="form-control" rows="4" placeholder="Enter your amendments" required></textarea>
                        </div>
                      </template>
                      <template #buttons>
                        <router-link replace :to="`/client-dashboard/quote/${slugifyQuote(quote)}`" class="button button--grey ml-1">Cancel</router-link>
                      </template>
                    </ajax-form>
                  </div>

                  <!-- reject -->
                  <div v-else-if="quoteAction === 'reject'" class="quote__actions__reject">
                    <h4 class="mt-0 mb-0">Reject</h4>
                    <p class="heading-desc mt-1 mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra, odio vel porttitor tempor, metus augue.</p>
                    <ajax-form :url="`/quote/${props.data.clientUUID}/${slugifyQuote(quote)}/action`">
                      <template #fields>
                        <input type="hidden" name="action" value="reject"/>
                        <div class="form-group">
                          <label :for="`reject-reason-${quote.ID}`">Reason <abbr class="required">*</abbr></label>
                          <textarea name="comments" :id="`reject-reason-${quote.ID}`" class="form-control" rows="4" placeholder="Enter a reason for rejection" required></textarea>
                        </div>
                      </template>
                      <template #buttons>
                        <router-link replace :to="`/client-dashboard/quote/${slugifyQuote(quote)}`" class="button button--grey ml-1">Cancel</router-link>
                      </template>
                    </ajax-form>
                  </div>
                </div>
                
                <!-- quote comments -->
                <div v-if="!quoteAction" class="quote__comments">
                  <h3 class="mt-0 mb-0">Comments</h3>
                  <p class="heading-desc mt-1 mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra, odio vel porttitor tempor, metus augue.</p>
                  <div class="quote__comments__container mt-4">
                    <div class="quote__comments__comment mb-3">
                      <h4 class="mt-0 mb-1">Chatter <span class="heading-desc">&mdash; 18/02/2021</span></h4>
                      <div class="quote__comments__comment__inner" v-html="quote.chatter_commentary"/>
                    </div>
                    <div v-if="quote.client_comments" class="quote__comments__comment">
                      <h4 class="mt-0 mb-1">{{ props.data.clientName }} <span class="heading-desc">&mdash; 24/02/2021</span></h4>
                      <div class="quote__comments__comment__inner" v-html="quote.client_comments"/>
                    </div>
                  </div>

                  <!-- comment form -->
                  <!--<ajax-form :url="`/quote/${props.data.clientUUID}/${slugifyQuote(quote)}/comment`" buttonText="Post" class="quote__comments__comment-form mt-4 pt-4">
                    <template #fields>
                      <div class="form-group">
                        <label :for="`comment-msg-${quote.ID}`">Comment</label>
                        <textarea :id="`comment-msg-${quote.ID}`" name="comment_msg" class="form-control" rows="4" placeholder="Enter your comment here..."></textarea>
                      </div>
                    </template>
                  </ajax-form>-->
                </div>
              </div>
            </div>
          </template>
        </modal>

        <h3 class="mt-5">Quotation</h3>
        <table class="table table--sm table--align-left" v-table-sort="fn => props.data.quotes.sort(fn)">
          <thead>
            <tr>
              <th width="15%">Quote Number</th>
              <th width="25%">Name</th>
              <th width="10%">State</th>
              <th width="12%" class="string" v-column-sort:Date>Date</th>
              <th width="15%" class="money" v-column-sort:AmountIncludingTax>Cost</th>
              <th class="no-hover">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="quote in props.data.quotes" :key="quote.id">
              <td>
                <router-link :to="`/client-dashboard/quote/${slugifyQuote(quote)}`">{{ quote.ID }}</router-link>
              </td>
              <td>
                <router-link :to="`/client-dashboard/quote/${slugifyQuote(quote)}`">{{ quote.Name }}</router-link>
              </td>
              <td class="capitalize">{{ quote.status }}</td>
              <td>{{ formatDate(quote.Date) }}</td>
              <td>{{ formatPrice(quote.AmountIncludingTax) }}</td>
              <td class="align-right">
                <div v-if="quote.status === 'sent'">
                  <router-link :to="`/client-dashboard/quote/${slugifyQuote(quote)}/accept`">Accept</router-link>
                  <router-link :to="`/client-dashboard/quote/${slugifyQuote(quote)}/amend`" class="button button--sm button--grey mr-1">Amend</router-link>
                  <router-link :to="`/client-dashboard/quote/${slugifyQuote(quote)}/reject`" class="button button--sm button--grey">Reject</router-link>
                </div>
              </td>
            </tr>
            <!-- empty -->
            <tr v-if="isEmptyObject(props.data.quotes)" class="table__empty">
              <td colspan="6">No quotes have been added.</td>
            </tr>
          </tbody>
        </table>
        <h3 class="mt-5">Current Jobs</h3>
        <table class="table table--sm table--align-left" v-table-sort="fn => props.data.jobs.sort(fn)">
          <thead>
            <tr>
              <th width="12%">Job Number</th>
              <th>Job Name</th>
              <th width="15%" class="string" v-column-sort:startDate>Start Date</th>
              <th width="18%" class="string" v-column-sort:manager>Account Handler</th>
              <th width="12%" class="string" v-column-sort:state>State</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="job in props.data.jobs" :key="job.jobUUID">
              <td><router-link :to="`/client-project/${job.jobUUID}`">{{ job.jobRef }}</router-link></td>
              <td><router-link :to="`/client-project/${job.jobUUID}`">{{ job.jobName }}</router-link></td>
              <td>{{ formatDate(job.startDate) }}</td>
              <!--<td>-</td>-->
              <td>{{ job.manager }}</td>
              <td>{{ job.state }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </template>
  </promise>
</template>

<script>
import { ref, computed } from 'vue'
import { mapState } from 'vuex'
import { useMeta } from 'vue-meta'
import Promise from '@/components/promise.vue'
import UserCard from '@/components/usercard.vue'
import Modal from '@/components/modal.vue'
import Avatar from '@/components/avatar.vue'
import {
  isEmptyObject,
  formatDate,
  formatPrice,
  slugifyQuote,
  unslugifyQuote,
  hasAtleastOnePrimaryContact,
} from '@/utils'
import AjaxForm from '@/components/ajax-form.vue'
import { httpPost } from '@/http'

export default {
  components: {
    Promise,
    UserCard,
    Modal,
    Avatar,
    AjaxForm,
  },
  setup() {
    // TODO : use metaInfo() once https://github.com/nuxt/vue-meta/issues/699 is resolved.
    const title = ref('');
    const updateTitle = (data) => title.value = `${data.clientName}`;
    useMeta(computed(() => ({ title: title.value })));

    return { updateTitle };
  },
  data() {
    return {
      lastOpenModal: null,

      // TODO : move into quotation component
      quoteAction: null,
    };
  },
  methods: {
    isEmptyObject, formatDate, formatPrice, slugifyQuote, unslugifyQuote, hasAtleastOnePrimaryContact,
    ready(data) {
      let { quote_ref, action } = this.$route.params;
      if (quote_ref) {
        // find quote
        const ref = unslugifyQuote(quote_ref);
        const quote = Object.keys(data.quotes).find(quote => data.quotes[quote].quote_ref === ref);
        if (quote) {
          this.lastOpenModal = `view-quote-${quote_ref}`;
          this.quoteAction = action;
          this.$nextTick(() => this.openModal(this.lastOpenModal));
        }
      }
    },
    /**
     * Get the name of a contact from a contact UUID
     */
    getContactName(props, uuid) {
      const result = props.data.contacts.find(contact => contact.contactUUID === uuid);
      return result?.name;
    },
    /**
     * HTTP post method to accept a quote
     */
    acceptQuote(props, quote) {
      const formData = new FormData();
      formData.append('action', 'approve');
      formData.append('comments', '');

      httpPost(`/quote/${props.data.clientUUID}/${slugifyQuote(quote)}/action`)
        .then(() => {
          quote.status = 'approved';
          quote.status_updated_at = new Date().toLocaleDateString('en-gb');
          quote.client_comments = '';
        })
        .catch(() => alert('Failed to accept quote!'));
    },
    /**
     * Quote modal popup closed action, update current router URL
     */
    quoteModalClosed() {
      this.$router.push(`/client-dashboard`);
    },
  },
  computed: mapState([
    'user',
  ]),
  watch: {
    '$route': function() {
      const { quote_ref, action } = this.$route.params;
      if (quote_ref) {
        this.lastOpenModal = `view-quote-${quote_ref}`;
        this.quoteAction = action;
        this.$nextTick(() => this.openModal(this.lastOpenModal));
      } else if (this.lastOpenModal) {
        this.closeModal(this.lastOpenModal);
        this.lastOpenModal = null;
        this.quoteAction = null;
      }
    },
  },
}
</script>