<template>
  <card class="card usercard-container">
    <template #header>
      <div class="d-flex align-items-center justify-content-between">
        <h4>{{ contact.name }}</h4>
        <button v-if="false" class="button button--icon button--grey" @click="flipped = !flipped">
          <template v-if="!flipped">
            <inline-svg :src="require('@/assets/icons/ellipsis.svg')" fill="currentColor" width="12" height="12" :aria-label="`Read more about ${contact.name}`"/>
          </template>
          <template v-else>
            <inline-svg :src="require('@/assets/icons/close.svg')" fill="currentColor" width="12" height="12" aria-label="Close read more"/>
          </template>
        </button>
      </div>
    </template>
    <template #content>
      <div class="usercard" :class="{'flipped': flipped }">
        <div class="usercard-face">
          <div class="d-flex flex-column align-items-center">
            <Avatar :string="contact.name" class="userinfo__image-container--lg mb-2"/>
            <ul>
              <li><strong>{{ contact.position }}</strong></li>
              <li><a href="#" target="_blank" rel="noopener">{{ contact.email }}</a></li>
              <li><a href="#" target="_blank" rel="noopener">{{ contact.mobile }}</a></li>
            </ul>
          </div>
        </div>
        <div class="usercard-face usercard-face--back">
          <p class="mt-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pharetra, odio vel porttitor tempor, metus augue.</p>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import Card from '@/components/card.vue'
import Avatar from '@/components/avatar.vue'

export default {
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  components: {
    Card,
    Avatar,
  },
  data() {
    return {
      flipped: false,
    };
  },
}
</script>