<template>
  <div class="modal modal-animation" :id="`modal-${id}`" aria-hidden="true" ref="el">
    <div class="modal__overlay" tabindex="-1" data-micromodal-close>
      <div class="modal__container" role="dialog" aria-modal="true" :aria-labelledby="`modal-${id}-title`">
        <header class="modal__header">
          <h2 class="modal__title" :id="`modal-${id}-title`">
            <slot name="title">
              Title
            </slot>
          </h2>
          <button class="modal__close" aria-label="Close modal" data-micromodal-close></button>
        </header>
        <main class="modal__content" :id="`modal-${id}-content`">
          <slot name="content">
            <p>Content</p>
          </slot>
        </main>
        <footer v-if="showFooter" class="modal__footer">
          <slot name="footer"/>
          <button v-if="showFooterCloseButton" class="button button--grey ml-1" data-micromodal-close aria-label="Close this dialog window">{{ closeButtonText }}</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['close'],
  props: {
    /**
     * Unique ID of the modal
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Boolean to show/hide the modal footer
     */
    showFooter: {
      type: Boolean,
      default: true,
    },
    /**
     * Boolean to show/hide the default modal close button
     */
    showFooterCloseButton: {
      type: Boolean,
      default: true,
    },
    /**
     * Text of the default modal close button
     */
    closeButtonText: {
      type: String,
      default: 'Close',
    },
  },
  mounted() {
    this.$refs.el.addEventListener('modalclose', this.modalClose);
  },
  beforeUnmount() {
    this.$refs.el.removeEventListener('modalclose', this.modalClose);
  },
  methods: {
    modalClose() {
      this.$emit('close');
    },
  },
}
</script>